import React, { useState } from "react";
import { Users, Zap, BarChart, X } from "lucide-react";
import { Link } from 'react-router-dom';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg relative max-w-md w-full">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const VimeoPlayer = () => (
  <div className="w-full md:w-1/2">
    <div className="relative w-full h-0 pb-[56.25%]">
      <iframe
        src="https://player.vimeo.com/video/1023676635?quality=1080p&title=0&byline=0&portrait=0&transparent=0&controls=1&responsive=1"
        className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  </div>
);

const trackEvent = (category, action, label) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
};

const LandingPage = () => {
  const [formStatus, setFormStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [earlyAccessStatus, setEarlyAccessStatus] = useState("");

  const handleSubmit = async (e, formType) => {
    e.preventDefault();
    const status =
      formType === "contact" ? setFormStatus : setEarlyAccessStatus;
    status("submitting");

    trackEvent('Form', 'Submit', formType);

    try {
      const response = await fetch("https://formspree.io/f/mzzbzrbr", {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        status("success");
        trackEvent('Form', 'Success', formType);
        e.target.reset();
        if (formType === "earlyAccess") {
          setTimeout(() => {
            setIsModalOpen(false);
            setEarlyAccessStatus("");
          }, 3000);
        }
      } else {
        status("error");
        trackEvent('Form', 'Error', formType);
      }
    } catch (error) {
      status("error");
      trackEvent('Form', 'Error', formType);
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="container mx-auto px-4">
        <header className="flex justify-between items-center py-6">
          <div className="text-teal-500 font-bold text-2xl">DOXASTIC</div>
          <nav>
            <ul className="flex space-x-6">
              <li>
                <a href="#features" className="hover:text-teal-500">
                  Features
                </a>
              </li>
              <li>
                <a href="#about" className="hover:text-teal-500">
                  About
                </a>
              </li>
              <li>
                <Link to="/blog" className="hover:text-teal-500">
                  Blog
                </Link>
              </li>
              <li>
                <a href="#contact" className="hover:text-teal-500">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <div className="space-x-4">
            <a
              href="https://app.doxastic.xyz"
              className="bg-gray-700 px-4 py-2 rounded"
              onClick={() => trackEvent('Navigation', 'Click', 'Sign In')}
            >
              Sign in
            </a>
            <button
              onClick={() => {
                setIsModalOpen(true);
                trackEvent('Navigation', 'Click', 'Request Access');
              }}
              className="bg-teal-500 text-black px-4 py-2 rounded font-semibold"
            >
              Request access
            </button>
          </div>
        </header>

        <main className="py-20">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="max-w-xl mb-12 md:mb-0 md:mr-8">
              <h1 className="text-5xl font-bold mb-6">
                Iterative LLM fine-tuning
                <br />
                <span className="text-teal-500">and data labeling tool</span>
              </h1>
              <p className="text-xl mb-8">
                Doxastic offers a no-code SaaS solution for managing, labeling,
                and fine-tuning language models. Create custom AI models using
                your domain knowledge and proprietary datasets, without
                specialized hardware or ML expertise.
              </p>
              <div className="space-x-4">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-teal-500 text-black px-6 py-3 rounded-lg text-lg font-semibold"
                >
                  Request access
                </button>
                <button className="bg-gray-700 px-6 py-3 rounded-lg text-lg">
                  Learn more
                </button>
              </div>
            </div>

            <VimeoPlayer />
          </div>
        </main>

        <section id="features" className="py-20">
          <h2 className="text-3xl font-bold mb-12">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Users className="w-12 h-12 text-teal-500" />}
              title="No-Code Solution"
              description="Create custom AI models without specialized hardware or machine learning knowledge."
            />
            <FeatureCard
              icon={<Zap className="w-12 h-12 text-teal-500" />}
              title="Iterative Development"
              description="Rapidly experiment, refine, evaluate, and deploy custom AI models with ease."
            />
            <FeatureCard
              icon={<BarChart className="w-12 h-12 text-teal-500" />}
              title="Domain Expertise Integration"
              description="Leverage your proprietary datasets and domain knowledge in model creation."
            />
          </div>
        </section>

        <section id="about" className="py-20">
          <h2 className="text-3xl font-bold mb-8">About Us</h2>
          <p className="text-xl mb-6">
            Founded by a team with experience in hedge funds and AI development,
            Doxastic was born from the need for efficient custom LLM fine-tuning
            tools. Our platform is designed for both technical and non-technical
            users, enabling you to leverage your domain expertise in AI model
            creation.
          </p>
          <p className="text-xl">
            Currently in MVP stage with test users, we're rapidly developing our
            platform to meet the growing demand for customized AI solutions.
          </p>
        </section>

        <section id="contact" className="py-20">
          <h2 className="text-3xl font-bold mb-12">Contact Us</h2>
          <form
            onSubmit={(e) => handleSubmit(e, "contact")}
            className="space-y-4 max-w-2xl mx-auto"
          >
            <input
              type="text"
              name="name"
              placeholder="Name"
              required
              className="w-full p-2 rounded bg-gray-800"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              className="w-full p-2 rounded bg-gray-800"
            />
            <textarea
              name="message"
              placeholder="Message"
              required
              rows="4"
              className="w-full p-2 rounded bg-gray-800"
            ></textarea>
            <button
              type="submit"
              disabled={formStatus === "submitting"}
              className="bg-teal-500 text-black px-6 py-2 rounded font-semibold w-full"
            >
              {formStatus === "submitting" ? "Sending..." : "Send Message"}
            </button>
            {formStatus === "success" && (
              <p className="text-green-500">Message sent successfully!</p>
            )}
            {formStatus === "error" && (
              <p className="text-red-500">
                There was an error sending your message. Please try again.
              </p>
            )}
          </form>
        </section>
      </div>

      <footer className="bg-gray-800 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between items-center">
            <div className="text-teal-500 font-bold text-xl mb-4 md:mb-0">
              DOXASTIC
            </div>
            <nav className="mb-4 md:mb-0">
              <ul className="flex flex-wrap space-x-6">
                <li>
                  <a href="#" className="hover:text-teal-500">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#features" className="hover:text-teal-500">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#about" className="hover:text-teal-500">
                    About
                  </a>
                </li>
                <li>
                  <a href="#contact" className="hover:text-teal-500">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
            <div className="w-full md:w-auto text-center md:text-left">
              <p>&copy; 2024 Doxastic. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Request Early Access</h2>
        <form
          onSubmit={(e) => handleSubmit(e, "earlyAccess")}
          className="space-y-4"
        >
          <input
            type="email"
            name="email"
            placeholder="Your email"
            required
            className="w-full p-2 rounded bg-gray-700"
          />
          <input type="hidden" name="form-type" value="early-access" />
          <button
            type="submit"
            disabled={earlyAccessStatus === "submitting"}
            className="w-full bg-teal-500 text-black px-4 py-2 rounded font-semibold"
          >
            {earlyAccessStatus === "submitting"
              ? "Requesting..."
              : "Request Access"}
          </button>
          {earlyAccessStatus === "success" && (
            <p className="text-green-500">Thank you for your request!</p>
          )}
          {earlyAccessStatus === "error" && (
            <p className="text-red-500">
              There was an error. Please try again.
            </p>
          )}
        </form>
      </Modal>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gray-800 p-6 rounded-lg">
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default LandingPage;
